import { Box, CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import MarkdownPage from '../components/MarkdownPage';
import { useTranslation } from 'react-i18next';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebaseConfig';


const PrivacyPolicyPage = () => {
    const { i18n } = useTranslation();
    const language = i18n.resolvedLanguage;
    const [content, setContent] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const querySnapshot = await getDocs(collection(db, "apartments"));
                const documents = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                const content = documents.find(obj => obj.id === 'privacy-policy')[language];

                setContent(content);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [language]);

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
                <p>Error: {error}</p>
            </Box>
        );
    }

    return (<Box
        sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%',
            py: 5,
        }}>
        <MarkdownPage content={content} />
    </Box>
    );
};

export default PrivacyPolicyPage;