import React from 'react';
import { Grid2, Box } from '@mui/material';
import Apartment from './Apartment';

const ApartmentGrid = ({ apartments }) => {
    return (
        <Box sx={{
            m: 0,
            p: 0,
            maxWidth: {
                xs: '346px',
                sm: '346px',
                md: '692px',
                lg: '1038px',
                xl: '1038px',
            },
        }}>
            <Grid2 container spacing={0} sx={{
                margin: 0,
                p: 0
            }}>
                {apartments.map((apartment, index) => (
                    <Grid2 item='true' xs={12} sm={6} md={4} key={index}>
                        <Apartment
                            apartment={apartment}
                        />
                    </Grid2>
                ))}
            </Grid2>
        </Box>
    );
};

export default ApartmentGrid;
