import React, { useEffect } from 'react';
import {
    Card,
    CardContent,
    CardActions,
    Typography,
    Chip,
    IconButton,
    Button,
    Box,
} from '@mui/material';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import { useTranslation } from 'react-i18next';
import useLanguageNavigate from '../hooks/useLanguageNavigate';

const Apartment = ({ apartment }) => {
    const { t, i18n } = useTranslation();
    const navigate = useLanguageNavigate();
    const language = i18n.resolvedLanguage;

    const title = () => {
        if (apartment.translations.hasOwnProperty(language)) {
            return apartment.translations[language].title;
        }

        return apartment.title;
    };

    useEffect(() => {
        // Load the external script when the component mounts
        const script = document.createElement('script');
        script.src = 'https://panel.hotres.pl/public/api/hotres_v4_popup.js';
        script.type = 'text/javascript';
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    const handleExpandClick = () => {
        if (window.showHotresPopup) {
            window.showHotresPopup({
                oid: 4318,
                lang: language,
                tid: apartment.type_id,
                template: 'standalone',
                action: 'room',
            });
        } else {
            console.error('Hotres popup script not loaded yet.');
        }
    };

    const handleBookNowClick = () => {
        const tags = apartment.tags;
        const rid = () => {
            if (tags.includes('waw')) {
                return 26371;
            }
            if (tags.includes('gda')) {
                return 26373;
            }
            if (tags.includes('laguna')) {
                return 26363;
            }
            if (tags.includes('hiszpania')) {
                return 26369;
            }
        }

        navigate(`/book/${rid()}`);
    };

    return (
        <Card
            onClick={handleExpandClick}
            sx={{
                width: '330px',
                height: '440px',
                borderRadius: 3,
                boxShadow: 3,
                m: 1,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
            }}
        >
            <Box
                sx={{
                    height: '280px',
                    width: '100%',
                    overflow: 'hidden',
                    borderTopLeftRadius: 12,
                    borderTopRightRadius: 12,
                }}
            >
                <img
                    src={apartment.photo}
                    alt={title()}
                    style={{
                        height: '100%',
                        width: '100%',
                        objectFit: 'cover',
                    }}
                />
            </Box>
            <CardContent
                sx={{
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                }}
            >
                <Chip
                    label={apartment.city}
                    variant="outlined"
                    color="primary"
                    sx={{
                        mb: 1,
                        color: 'black',
                        maxWidth: 'fit-content',
                    }}
                />
                <Typography
                    variant="h6"
                    sx={{
                        fontWeight: 'bold',
                        overflow: 'hidden',
                        display: '-webkit-box',
                        WebkitLineClamp: 3,
                        WebkitBoxOrient: 'vertical',
                        textOverflow: 'ellipsis',
                        height: '4.5em',
                    }}
                >
                    {title()}
                </Typography>
            </CardContent>
            <CardActions>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={(event) => {
                        event.stopPropagation();
                        handleBookNowClick();
                    }}
                    sx={{ textTransform: 'none', borderRadius: 5 }}
                >
                    {t('bookNow')}
                </Button>
                <Box sx={{ flexGrow: 1 }} />
                <IconButton
                    onClick={(event) => {
                        event.stopPropagation();
                        handleExpandClick();
                    }}
                    sx={{
                        backgroundColor: 'rgba(255, 255, 255, 0.8)',
                        '&:hover': {
                            backgroundColor: 'rgba(255, 255, 255, 1)',
                        },
                    }}
                >
                    <OpenInFullIcon />
                </IconButton>
            </CardActions>
        </Card>
    );
};

export default Apartment;
