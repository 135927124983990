import React from 'react';
import { Box, Typography } from '@mui/material';
import ManagementCard from '../components/ManagementCard';
import { useTranslation } from 'react-i18next';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
import ArchitectureIcon from '@mui/icons-material/Architecture';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import PlumbingIcon from '@mui/icons-material/Plumbing';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';

const ManagementPage = () => {
    const { t } = useTranslation();

    const icons = [
        <SentimentSatisfiedAltIcon />,
        <AssignmentIcon />,
        <AttachMoneyIcon />,
        <CleaningServicesIcon />,
        <ArchitectureIcon />,
        <CalendarMonthIcon />,
        <PlumbingIcon />,
        <PhotoCameraIcon />,
    ];
    const cards = icons.map((icon, index) => {
        return {
            title: t(`managementTitle${index + 1}`),
            description: t(`managementDescription${index + 1}`),
            icon: icon,
        }
    }
    );

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%',
                py: 5,
            }}>
            <Typography variant="h4" sx={{ mb: 2, textAlign: 'center' }}>
                {t('ourServices')}
            </Typography>
            <Box
                sx={{
                    width: '100%',
                    maxWidth: '600px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    mb: 2,
                }}
            >
                <Typography variant="body1" sx={{ mb: 2, textAlign: 'center' }}>
                    {t('ourServicesSubtitle')}
                </Typography>
            </Box>
            <Box sx={{ display: 'flex', gap: '16px', flexWrap: 'wrap', padding: '20px', maxWidth: '800px' }}>
                {cards.map((card, index) => (
                    <ManagementCard
                        key={index}
                        title={card.title}
                        description={card.description}
                        icon={card.icon}
                    />
                ))}
            </Box>
        </Box>
    );
};

export default ManagementPage;
