import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function useLanguageNavigate() {
    const navigate = useNavigate();
    const { i18n } = useTranslation();

    const lang = i18n.resolvedLanguage;

    const languageNavigate = useCallback(
        (to, options) => {
            // Ensure 'to' is a string or an object
            let path;
            if (typeof to === 'string') {
                path = `/${lang}${to.startsWith('/') ? '' : '/'}${to}`;
            } else if (typeof to === 'object' && to.pathname) {
                path = {
                    ...to,
                    pathname: `/${lang}${to.pathname.startsWith('/') ? '' : '/'}${to.pathname}`,
                };
            } else {
                console.error('navigate function expects a string or object with pathname');
                return;
            }
            navigate(path, options);
        },
        [navigate, lang]
    );

    return languageNavigate;
}

export default useLanguageNavigate;
