import React from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Logo from './Logo';
import useLanguageNavigate from '../hooks/useLanguageNavigate';

const LogoLink = () => {
    const { t } = useTranslation();
    const navigate = useLanguageNavigate();

    const handleClick = () => {
        navigate('/');
    };

    return (
        <div
            onClick={handleClick}
            style={{ display: 'flex', alignItems: 'center', textDecoration: 'none', color: 'inherit', cursor: 'pointer' }}
        >
            <Logo style={{ marginRight: '8px' }} />
            <Typography variant="h6">{t('title')}</Typography>
        </div>
    );
};

export default LogoLink;
