import { Box } from '@mui/material';
import React from 'react';
import Contact from '../components/Contact';


const ContactPage = () => {
    return (<Box
        sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%',
            py: 5,
        }}>
        <Contact/>
    </Box>
    );
};

export default ContactPage;