import React, { useState } from 'react';
import { AppBar, Toolbar, IconButton, Button, Drawer, List, ListItem, ListItemText, Box } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import LanguageSwitcher from './LanguageSwitcher';
import LogoLink from './LogoLink';

const Navbar = ({ actions = [] }) => {
    const [mobileOpen, setMobileOpen] = useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const drawer = (
        <List>
            {actions.map((action, index) => (
                <ListItem
                    button='true'
                    key={index}
                    onClick={() => {
                        action.onClick();
                        handleDrawerToggle();
                    }}
                >
                    <ListItemText primary={action.label} />
                </ListItem>
            ))}
            <LanguageSwitcher />
        </List>
    );

    return (
        <div>
            <AppBar position="static" sx={{ backgroundColor: 'white', color: 'black' }}>
                <Toolbar>
                    <LogoLink />
                    <Box sx={{ display: { xs: 'none', sm: 'none', md: 'flex' }, alignItems: 'center', ml: 'auto' }}>
                        {actions.slice(0, -1).map((action, index) => (
                            <Button
                                key={index}
                                color="inherit"
                                onClick={action.onClick}
                                sx={{ borderRadius: '50px', mr: 1 }}
                            >
                                {action.label}
                            </Button>
                        ))}
                        <Button
                            color="inherit"
                            onClick={actions[actions.length - 1].onClick}
                            sx={(theme) => ({
                                backgroundColor: theme.palette.primary.main,
                                color: 'black',
                                borderRadius: '50px',
                                height: 'auto',
                                px: 1,
                                '&:hover': {
                                    backgroundColor: '#FFB700',
                                },
                            })}
                        >
                            {actions[actions.length - 1].label}
                        </Button>
                        <LanguageSwitcher />
                    </Box>

                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="end"
                        onClick={handleDrawerToggle}
                        sx={{ display: { xs: 'block', sm: 'block', md: 'none' }, ml: 'auto', mr: 1 }}
                    >
                        <MenuIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>

            <Drawer
                anchor="right"
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                    keepMounted: true,
                }}
                sx={{
                    display: { xs: 'block', sm: 'block', md: 'none' },
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: 240 },
                }}
            >
                {drawer}
            </Drawer>
        </div>
    );
};

export default Navbar;
