import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

function HotresWidget() {
    const { i18n } = useTranslation();
    const { rid } = useParams();

    useEffect(() => {
        const containerId = "hotresContainer";

        const initializeHotres = () => {
            const placeholder = document.getElementById("hotresPlaceholder");

            const existingContainer = document.getElementById(containerId);
            if (existingContainer) {
                existingContainer.remove();
            }

            const newContainer = document.createElement("div");
            newContainer.id = containerId;

            if (placeholder) {
                placeholder.appendChild(newContainer);
            } else {
                console.error("Hotres placeholder not found.");
            }

            if (window.createHotres) {
                window.createHotres({
                    oid: 4318,
                    lang: i18n.resolvedLanguage,
                    ...(rid !== undefined && { rid: parseInt(rid, 10) }),
                });
            } else {
                console.error("createHotres function is not available.");
            }
        };

        if (document.readyState === "complete") {
            initializeHotres();
        } else {
            window.addEventListener("load", initializeHotres);
        }

        return () => {
            window.removeEventListener("load", initializeHotres);

            const existingContainer = document.getElementById(containerId);
            if (existingContainer) {
                existingContainer.remove();
            }
        };
    }, [i18n.resolvedLanguage, rid]);

    return <div id="hotresPlaceholder"></div>;
}

export default HotresWidget;
