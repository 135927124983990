import React from "react";
import { Box, Typography } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import { useTranslation } from "react-i18next";

const Reviews = () => {
    const { t } = useTranslation();

    return (
        <Box
            sx={{
                textAlign: "center",
                my: 4,
            }}
        >
            <Typography
                variant="h6"
                sx={{
                    fontSize: "1.6rem",
                    mb: 1,
                }}
            >
                {t('already')}{" "}
                <Box
                    component="span"
                    sx={{
                        p: 1,
                        backgroundColor: "rgba(5, 213, 173, 0.3)",
                    }}
                >
                    <strong>2000+</strong>
                </Box>{" "}
                {t('gaveReviews')}
            </Typography>
            <Typography
                variant="body1"
                sx={{
                    fontSize: "18px",
                }}
            >
                <Box
                    component="span"
                    sx={{
                        p: 2,
                    }}
                >
                    {t('averageRating')}
                </Box>
                <Box
                    component="span"
                    sx={{
                        verticalAlign: "middle",
                    }}
                >
                    {Array(5)
                        .fill(0)
                        .map((_, index) => (
                            <StarIcon
                                key={index}
                                sx={{
                                    color: "rgb(255, 205, 78)",
                                    mt: 0.4,
                                    height: "28px",
                                }}
                            />
                        ))}
                </Box>
            </Typography>
        </Box>
    );
};

export default Reviews;
