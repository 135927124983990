import React from "react";
import { useTranslation } from 'react-i18next';
import { Box, MenuItem, Select } from "@mui/material";
import { useNavigate } from 'react-router-dom';

const LanguageSwitcher = () => {
    const { i18n } = useTranslation();
    const navigate = useNavigate();

    const language = i18n.resolvedLanguage;

    const handleChange = (event) => {
        const newLanguage = event.target.value;
        if (newLanguage !== i18n.resolvedLanguage) {
            i18n.changeLanguage(newLanguage);
            navigate(`/${newLanguage}${window.location.pathname.slice(3)}`);
        }
    };

    return (
        <Box sx={{ ml: 2 }}>
            <Select
                value={language}
                onChange={handleChange}
                style={{ width: "120px" }}
            >
                <MenuItem value="pl">Polski</MenuItem>
                <MenuItem value="en">English</MenuItem>
                <MenuItem value="es">Español</MenuItem>
            </Select>
        </Box>
    );
};

export default LanguageSwitcher;
