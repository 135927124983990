import React from 'react';
import { Box } from '@mui/material';
import HotresWidget from '../components/Hotres';

const BookPage = () => {
    return <Box
        sx={{
            alignItems: 'center',
            width: '100%',
        }}>
        <HotresWidget />
    </Box >
};

export default BookPage;