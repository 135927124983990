import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

const ManagementCard = ({ title, description, icon }) => {
    return (
        <Card sx={{ borderRadius: 2, padding: 4, display: 'inline-flex', width: '100%' }}>
            <CardContent sx={{ alignItems: 'flex-start' }}>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography variant="h6" component="div" gutterBottom>
                        {title}
                    </Typography>
                    <Box component="span" sx={{ fontSize: '2rem', color: '#FFD700' }}>
                        {React.cloneElement(icon, { fontSize: 'inherit' })}
                    </Box>
                </Box>
                <Typography variant="body2" color="text.secondary">
                    {description}
                </Typography>
            </CardContent>
        </Card>
    );
};

export default ManagementCard;
