import React, { useEffect, useState } from 'react';
import { Box, Typography, CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import SelectableChips from '../components/SelectableChips';
import ApartmentGrid from '../components/ApartmentGrid';
import { db } from '../firebaseConfig';
import { collection, getDocs } from "firebase/firestore";
import Reviews from '../components/Reviews';

const HomePage = () => {
    const { t } = useTranslation();
    const [apartments, setApartments] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selected, setSelected] = useState('all');

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const querySnapshot = await getDocs(collection(db, "apartments"));
                const dataArray = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                const json = dataArray.find(obj => obj.id === 'apartments').data;
                const apartments = JSON.parse(json);

                setApartments(apartments);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
                <p>Error: {error}</p>
            </Box>
        );
    }

    const tags = ['all', ...apartments.map((apartment, _) => {
        return apartment.tags.split(',').map((tag, _) => tag.split('-')[0]);
    }).flat().filter((value, index, self) => self.indexOf(value) === index)];

    const options = tags.map((tag, index) => {
        return {
            tag: tag,
            label: t(tag)
        }
    })

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%',
                py: 5,
            }}
        >
            <Reviews />
            <Typography variant="h4" sx={{ my: 2, textAlign: 'center' }}>
                {t('ourApartments')}
            </Typography>

            <Box
                sx={{
                    width: '100%',
                    maxWidth: '600px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    mb: 2,
                }}
            >
                <Typography variant="body1" sx={{ mb: 2, textAlign: 'center' }}>
                    {t('checkOurApartments')}
                </Typography>
            </Box>
            <Box sx={{ mb: 2, mx: 1 }}>
                <SelectableChips setSelected={setSelected} selected={selected} options={options} />
            </Box>
            <ApartmentGrid apartments={apartments.filter((apartment, _, __) => selected === 'all' || apartment.tags.includes(selected))} />
        </Box>
    );
};

export default HomePage;
