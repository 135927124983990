import React from "react";
import ReactMarkdown from "react-markdown";

const MarkdownPage = ({ content }) => {
    const preprocessMarkdown = (markdown) => {
        return markdown.replaceAll('\\n', '\n').replaceAll(' -', '-');
    };

    return (
        <div style={{ padding: "1rem", lineHeight: "1.6", fontSize: "1rem", maxWidth: '1000px' }}>
            <ReactMarkdown breaks={true}>{preprocessMarkdown(content)}</ReactMarkdown>
        </div>
    );
};

export default MarkdownPage;
