import { useParams, Navigate, Routes, Route } from 'react-router-dom';
import HomePage from '../pages/HomePage';
import ManagementPage from '../pages/ManagementPage';
import BookPage from '../pages/BookPage';
import ContactPage from '../pages/ContactPage'
import TermsOfUsePage from '../pages/TermsOfUsePage';
import PrivacyPolicyPage from '../pages/PrivacyPolicyPage';
import { useTranslation } from 'react-i18next';

const LanguageRoutes = () => {
    const { lang } = useParams();
    const { i18n } = useTranslation();

    const validLanguages = ['pl', 'en', 'es'];
    if (!validLanguages.includes(lang)) {
        i18n.changeLanguage('en');
        return <Navigate to="/en" replace />;
    }

    return (
        <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="management" element={<ManagementPage />} />
            <Route path="contact" element={<ContactPage />} />
            <Route path="book" element={<BookPage />} />
            <Route path="book/:rid" element={<BookPage />} />
            <Route path="terms-of-use" element={<TermsOfUsePage />} />
            <Route path="privacy-policy" element={<PrivacyPolicyPage />} />
            <Route path="*" element={<Navigate to={`/${lang}`} replace />} />
        </Routes>
    );
};

export default LanguageRoutes;
