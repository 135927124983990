import { Navigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const RedirectToLanguage = () => {
    const { i18n, ready } = useTranslation();

    if (!ready) {
        return null;
    }

    const validLanguages = ['pl', 'en'];

    let detectedLanguage = i18n.resolvedLanguage || 'pl';

    if (!validLanguages.includes(detectedLanguage)) {
        detectedLanguage = 'pl';
    }

    return <Navigate to={`/${detectedLanguage}`} replace />;
};

export default RedirectToLanguage;
