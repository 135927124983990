import React from 'react';
import { Box } from '@mui/material';
import superiorLogo from '../assets/superior-logo.svg';

const Logo = () => {
    return (
        <Box
            sx={ (theme) => ({
                width: '40px',
                height: '40px',
                backgroundColor: theme.palette.primary.main,
                borderRadius: '30%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                mr: 2,
            })}
        >
            <img src={superiorLogo} alt="Logo" style={{ width: '36px', height: '36px' }} />
        </Box>
    );
};

export default Logo;
